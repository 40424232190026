//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb {
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: 0;
  list-style: none;
  background-color: @breadcrumb-bg;
  display: inline-block;

  > li {
    display: inline-block;

    + li:nth-child(2):before {
      vertical-align: middle;
      width: 14px;
      height: 14px;
      display: inline-block;
      content: "";
      background-image: url(/static/images/right.svg);
      background-repeat: no-repeat;
    }

    + li:before {
      font: @breadcrumb-separator-font;
      content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @breadcrumb-color;
    }
  }

  > .active {
    color: @breadcrumb-active-color;
  }
}
