//
// Utility classes
// --------------------------------------------------

// Floats
// -------------------------

.clearfix {
  .clearfix();
}

.center-block {
  .center-block();
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}

.show, .block {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  .text-hide();
}

// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
}

// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}

.bg-body {
  background-color: @body-bg;
}

.bg-gray-base {
  background-color: @gray-base;
}

.bg-gray-darker {
  background-color: @gray-darker;
}

.bg-gray-dark {
  background-color: @gray-dark;
}

.bg-gray {
  background-color: @gray;
}

.bg-gray-light {
  background-color: @gray-light;
}

.bg-gray-lighter {
  background-color: @gray-lighter;
}

.shadowed-box {
  //.shadow();
  position: relative;
  z-index: 2;

  &.nav-justified {
    border: none;
    margin-bottom: 0;
  }
}

.shadowed-item {
  z-index: 1;
  .shadow-2();

  &.shadowed-item-hover {
    .shadow-1();

    &:hover,
    &-hover {
      .shadow-2();
    }

    &:active,
    &-active {
      .shadow-2();
    }

    &:focus,
    &-focus {
      .shadow-2();
    }
  }
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}
