//
// Tables
// --------------------------------------------------

table {
  background-color: @table-bg;
  font-size: @font-size-base;

  > thead > tr > th.tight-column,
  > tbody > tr > td.tight-column {
    width: 0.01%;
  }

  > thead > tr > th.text-vtop,
  > tbody > tr > td.text-vtop {
    vertical-align: top;
  }

  > thead > tr > th.text-vcenter,
  > tbody > tr > td.text-vcenter {
    vertical-align: middle;
  }

  > thead > tr > th.text-vbottom,
  > tbody > tr > td.text-vbottom {
    vertical-align: bottom;
  }

  > thead > tr > th.text-centered,
  > tbody > tr > td.text-centered {
    text-align: center;
    vertical-align: middle;
  }

  > thead > tr > th.table-counter {
    text-align: center;
    vertical-align: middle;
    width: 0.01%;
    white-space: nowrap;
  }

  > tbody > tr > td.table-counter {
    text-align: center;
    vertical-align: top;
    white-space: nowrap;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  &.table-fluid {
    word-break: break-all;
    word-wrap: break-word;
  }
}

caption {
  padding-top: @table-cell-padding;
  padding-bottom: @table-cell-padding;
  color: @table-header-text-color;
  text-align: center;
  font-size: @table-header-font-size;
}

th {
  text-align: center;
}

// Baseline styles

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: @line-height-computed;
  border: none;
  color: #333;

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: @table-cell-padding;
        line-height: @line-height-base;
        vertical-align: top;
        border-top: 1px solid @table-border-color;

        ul.list-unstyled {
          li + li {
            border-top: 1px solid #d9d9d9;
            margin-top: 5px;
            padding-top: 5px;
          }
        }
      }

      > td.btn-line {
        line-height: 33px;
      }
    }
  }

  // Middle align for column headings
  > thead > tr > th {
    vertical-align: middle;
    border-bottom: 1px solid @table-border-color;
    color: @table-header-text-color;
    text-align: center;
    font-size: @table-header-font-size;
    font-weight: 700;
    background-color: @table-header-bg-color;
    text-transform: uppercase;
    padding: @table-header-padding;
  }

  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child,
  > tbody:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }

  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid @table-border-color;
  }

  // Nesting
  .table {
    background-color: @body-bg;
  }

  > tbody > tr.table-group {
    font-weight: 700;
    background-color: @table-group-bg-color;

    > td {
      padding-left: (@table-cell-padding * 2);
      padding-right: (@table-cell-padding * 2);
    }
  }

  > tbody > tr > td.col-md-1,
  > thead > tr > th.col-md-1 {
    width: 8.33333%;
  }

  > tbody > tr > td.col-md-2,
  > thead > tr > th.col-md-2 {
    width: 16.66667%;
  }

  > tbody > tr > td.col-md-3,
  > thead > tr > th.col-md-3 {
    width: 25.0%;
  }

  > tbody > tr > td.col-md-4,
  > thead > tr > th.col-md-4 {
    width: 33.33333%;
  }

  > tbody > tr > td.col-md-5,
  > thead > tr > th.col-md-5 {
    width: 41.66667%;
  }

  > tbody > tr > td.col-md-6,
  > thead > tr > th.col-md-6 {
    width: 50.0%;
  }

  > tbody > tr > td.col-md-7,
  > thead > tr > th.col-md-7 {
    width: 58.33333%;
  }

  > tbody > tr > td.col-md-8,
  > thead > tr > th.col-md-8 {
    width: 66.66667%;
  }

  > tbody > tr > td.col-md-9,
  > thead > tr > th.col-md-9 {
    width: 75.0%;
  }

  > tbody > tr > td.col-md-10,
  > thead > tr > th.col-md-10 {
    width: 83.33333%;
  }

  > tbody > tr > td.col-md-11,
  > thead > tr > th.col-md-11 {
    width: 91.66667%;
  }

  > tbody > tr > td.col-md-12,
  > thead > tr > th.col-md-12 {
    width: 100%;
  }

  .btn-toolbar {
    white-space: nowrap; // prevent buttons from wrapping when in tight spaces (e.g., the table on the tests page)
    .btn, .btn-toolbar .btn-group, .btn-toolbar .input-group {
      float: inherit;
    }
  }
}

// Condensed table w/ half padding

.table-condensed {
  > thead {
    > tr {
      > th {
        padding: @table-condensed-header-padding;
      }
    }
  }

  > tbody > tr.table-group {
    > td {
      padding-left: (@table-condensed-cell-padding * 2);
      padding-right: (@table-condensed-cell-padding * 2);
    }
  }

  > tbody,
  > tfoot {
    > tr {
      > td {
        padding: @table-condensed-cell-padding;
      }
    }
  }
}

.table-large {
  > thead {
    > tr {
      > th {
        font-size: @table-header-large-font-size;
        line-height: @table-header-line-height;
        padding: @table-large-header-padding;
      }
    }
  }

  > tbody > tr.table-group {
    > td {
      padding-left: (@table-large-cell-padding * 2);
      padding-right: (@table-large-cell-padding * 2);
    }
  }

  > tbody,
  > tfoot {
    > tr {
      > td {
        padding: @table-large-cell-padding;
      }
    }
  }
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: 1px solid @table-border-color;

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid @table-border-color;
      }
    }
  }

  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: @table-bg-accent;
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody:hover td[rowspan],
  > tbody > tr.hover,
  > tbody > tr:hover {
    background-color: @table-bg-hover;
  }
}

.table-hover-tbody {
  tbody:hover {
    background-color: @table-bg-hover;
  }
}

.table-clickable {
  tr, td {
    cursor: pointer;
  }

  tbody tr, tr {
    &.active, &.active td, td.active {
      font-weight: bold;
      cursor: default;

      a {
        cursor: default;
      }
    }
  }
}

// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}

table {
  td,
  th {
    &[class*="col-"] {
      position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
.table-row-variant(active; @table-bg-active; @text-color);
.table-row-variant(success; #e7ffe1; @text-color);
.table-row-variant(info; #daebff; @text-color);
.table-row-variant(warning; #fcf6d6; @text-color);
.table-row-variant(danger; #fff3f4; @text-color);
.table-row-variant(inactive; #f1f1f1; @text-color);

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
  margin-bottom: @line-height-computed;

  > .table {
    margin-bottom: 0;
  }

  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid @table-border-color;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }

          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
