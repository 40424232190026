//
// Navs
// --------------------------------------------------

// Base class
// --------------------------------------------------

.nav {
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;
  &:extend(.clearfix all);

  > li {
    position: relative;
    display: block;

    > a {
      position: relative;
      display: block;
      padding: @nav-link-padding;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: @nav-link-hover-bg;
      }
    }

    // Disabled state sets text to gray and nukes hover/tab effects
    &.disabled {
      &:hover {
        box-shadow: none;
        cursor: @cursor-disabled;
      }

      & > a {
        color: @nav-disabled-link-color;
        pointer-events: none;

        &:hover,
        &:focus {
          color: @nav-disabled-link-hover-color;
          text-decoration: none;
          background-color: transparent;
          cursor: @cursor-disabled;
        }
      }
    }
  }

  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      background-color: @nav-link-hover-bg;
      border-color: @link-color;
    }
  }

  // Nav dividers (deprecated with v3.0.1)
  //
  // This should have been removed in v3 with the dropping of `.nav-list`, but
  // we missed it. We don't currently support this anywhere, but in the interest
  // of maintaining backward compatibility in case you use it, it's deprecated.
  .nav-divider {
    .nav-divider();
  }

  // Prevent IE8 from misplacing imgs
  //
  // See https://github.com/h5bp/html5-boilerplate/issues/984#issuecomment-3985989
  > li > a > img {
    max-width: none;
  }
}

// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  border-bottom: 1px solid transparent;

  > li {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: -1px;

    // Actual tabs (as links)
    > a {
      padding-left: @nav-tabs-link-padding-left;
      padding-top: @nav-tabs-link-padding-top;
      padding-right: @nav-tabs-link-padding-right;
      padding-bottom: @nav-tabs-link-padding-bottom;

      line-height: @line-height-base;
      text-transform: uppercase;
      color: @nav-tabs-inactive-link-color;
      background-color: transparent;
      font-size: 16px;

      &:hover {
        color: @nav-tabs-inactive-link-hover-color;
        background-color: transparent;

      }
    }

    &:hover {
      box-shadow: inset 0 -3px 0 0 @nav-tabs-link-hover-border-color;
    }

    // Active state, and its :hover to override normal :hover
    &.active {
      box-shadow: inset 0 -3px 0 0 @nav-tabs-border-color;

      & > a {
        &,
        &:hover,
        &:focus {
          color: @nav-tabs-active-link-hover-color;
          background-color: transparent;
          cursor: default;
          font-weight: bold;
        }
      }
    }
  }

  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      background-color: transparent;
      border-color: transparent;
      color: @nav-tabs-inactive-link-hover-color;
    }
  }

  // pulling this in mainly for less shorthand
  &.nav-justified {
    .nav-justified();
  }

  &.panel-heading {
    padding: 0;

    > li {
      > a {
        text-transform: none;
        font-weight: 600;
        padding: @panel-heading-padding;

        &:hover,
        &:focus {
          font-weight: 600;
        }
      }
    }
  }
}

// Pills
// -------------------------
.nav-pills {
  > li {
    float: left;

    // Links rendered as pills
    > a {
      border-radius: @nav-pills-border-radius;
    }

    + li {
      margin-left: 2px;
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: @nav-pills-active-link-hover-color;
        background-color: @nav-pills-active-link-hover-bg;
      }
    }
  }
}

// Stacked pills
.nav-stacked {
  > li {
    float: none;

    + li {
      margin-top: 2px;
      margin-left: 0; // no need for this gap between nav items
    }
  }
}

// Nav variations
// --------------------------------------------------

// Justified nav links
// -------------------------

.nav-justified {
  width: 100%;
  margin-bottom: -1px;

  > li {
    float: none;

    > a {
      text-align: center;
      padding-left: @nav-tabs-link-padding-top;
      padding-top: @nav-tabs-link-padding-top;
      padding-right: @nav-tabs-link-padding-top;
      padding-bottom: @nav-tabs-link-padding-bottom;
    }
  }

  > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
  }

  @media (min-width: @screen-sm-min) {
    > li {
      display: table-cell;
      width: 1%;
      vertical-align: middle;

      > a {
        margin-bottom: 0;
      }
    }
  }
}

// Tabbable tabs
// -------------------------

// Hide tabbable panes to start, show them when `.active`
.tab-content {
  > .tab-pane {
    display: none;
  }

  > .active {
    display: block;
  }

  padding: 20px;
  border: none;
  .shadow();
}

// Dropdowns
// -------------------------

// Specific dropdowns
.nav-tabs .dropdown-menu {
  // make dropdown border overlap tab border
  margin-top: -1px;
  // Remove the top rounded corners here since there is a hard edge above the menu
  .border-top-radius(0);
}

.tab-content + .form-buttons {
  margin-top: 20px;
}
