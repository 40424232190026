//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------
.btn-typo {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  white-space: nowrap;
  border: 1px solid transparent;
  .user-select(none);

  &,
  &.active,
  &.hover,
  &.focus {
    color: @text-color;
  }
}

.btn {
  border-collapse: separate;
  display: inline-block;
  margin-bottom: 2px; // размер тени по вертикали, иначе - в таблицах скролл появляется
  font-weight: @btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@btn-padding-base-vertical1; @btn-padding-base-horizontal1; @btn-padding-base-vertical2; @btn-padding-base-horizontal2; @btn-font-size-base; @line-height-base; @btn-border-radius-base);
  .user-select(none);
  box-shadow: @btn-box-shadow;
  .transition(box-shadow 0.3s cubic-bezier(0.38, 0, 0.28, 1));

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      .tab-focus();

    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: @btn-default-color;
    text-decoration: none;
    box-shadow: @btn-box-shadow-hover;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    box-shadow: @btn-box-shadow-active;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: @cursor-disabled;
    .opacity(.5);
    box-shadow: @btn-box-shadow-disabled;
  }

  a& {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}

.btn-primary {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}

// Success appears as green
.btn-success {
  .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
}

// Info appears as blue-green
.btn-info {
  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
}

// Warning appears as orange
.btn-warning {
  .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}

// Danger and error appear as red
.btn-danger {
  .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;
  font-weight: normal;
  border-radius: 0;
  box-shadow: none;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
    background-color: transparent;
  }

  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@btn-padding-large-vertical1; @btn-padding-large-horizontal1; @btn-padding-large-vertical2; @btn-padding-large-horizontal2; @btn-font-size-large; @line-height-large; @btn-border-radius-large);
}

.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@btn-padding-small-vertical1; @btn-padding-small-horizontal1; @btn-padding-small-vertical2; @btn-padding-small-horizontal2; @btn-font-size-base; @line-height-computed; @btn-border-radius-small);
  min-width: 90px;

  &.btn-thin {
    min-width: 0;
  }
}

.btn-xs {
  .button-size(@btn-padding-xs-vertical1; @btn-padding-xs-horizontal1; @btn-padding-xs-vertical2; @btn-padding-xs-horizontal2; @btn-font-size-base; @line-height-computed; @btn-border-radius-small);
}

// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
