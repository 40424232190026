// Progress bars

.progress-bar-variant(@color) {
  background-color: @color;

  .progress-bar-label {
    background-color: @color;
    .box-shadow(inset 0 -1px 0 rgba(0, 0, 0, .15));
  }

  // Deprecated parent class requirement as of v3.2.0
  .progress-striped & {
    #gradient > .striped();

    .progress-bar-label {
      #gradient > .striped();
    }
  }
}
