// WebKit-style focus

.tab-focus() {
  //// Default
  outline: none;
  //outline: thin dotted;
  //// WebKit
  //outline: 5px auto -webkit-focus-ring-color;
  //outline-offset: -2px;
}
