.bs-callout {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-image: none;
  border-radius: 5px;
  border: 1px solid #9dadb4;
  border-left-width: 5px;
  margin: 20px 0;
  padding: 10px;
  min-height: 45px;
  background-color: #ebf9ff;
  font-size: 13px;
  line-height: 15px;
  .shadow();
}

.bs-callout-warning {
  border-left-color: @brand-warning;
}

.bs-callout-danger {
  border-left-color: @brand-danger;
}

.bs-callout-info {
  border-left-color: @brand-primary;
}

.bs-callout > h1:first-child,
.bs-callout > h2:first-child,
.bs-callout > h3:first-child,
.bs-callout > h4:first-child,
.bs-callout > h5:first-child,
.bs-callout > h6:first-child {
  margin-top: 0;
}
