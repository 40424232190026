//
// Checkboxes
// --------------------------------------------------

@font-family-icon: 'FontAwesome';
@fa-var-check: "\f00c";
@check-icon: @fa-var-check;

.checkbox-variant(@parent, @color) {
  .checkbox-awesome.@{parent} {
    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
      & + label,
      & + span {
        &::before {
          background-color: @color;
          border-color: @color;
        }

        &::after {
          color: #fff;
        }
      }
    }
  }
}

.checkbox-variant-indeterminate(@parent, @color) {
  .checkbox-awesome.@{parent} {
    input[type="checkbox"]:indeterminate,
    input[type="radio"]:indeterminate {
      & + label,
      & + span {
        &::before {
          background-color: @color;
          border-color: @color;
        }

        &::after {
          background-color: #fff;
        }
      }

      &:disabled + label,
      &:disabled + span {
        &::after {
          background-color: #555555;
        }
      }
    }
  }
}

.checkbox-label-required {
  font-weight: bold;
  color: #ff0f00;
  content: '*  ';
}

.checkbox-awesome {
  padding-left: 20px;

  label {
    padding-left: 0;
    cursor: pointer;
  }

  input[type="checkbox"],
  input[type="radio"] {
    & + label,
    & + span {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding-left: 5px;
      min-height: 20px;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 16px;
        height: 16px;
        left: -18px;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid @input-border;
        border-radius: 3px;
        background-color: #fff;
        .transition(~"border 0.15s ease-in-out, color 0.15s ease-in-out");
      }

      &::after {
        display: inline-block;
        position: absolute;
        width: 16px;
        height: 16px;
        line-height: 16px;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        margin-left: -20px;
        font-size: 13px;
        color: @input-color;
        text-align: center;
      }
    }

    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:focus {
      & + label,
      & + span {
        color: #000;

        &::before {
          border-color: @input-border-focus !important;
          outline: none;
          outline: thin dotted;
          outline: 1px auto @input-border-focus;
        }
      }
    }

    &:checked + label::after,
    &:checked + span::after {
      font-family: @font-family-icon;
      content: @check-icon;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled + label,
    &:disabled + span {
      color: @input-disabled-color !important;

      &::before {
        cursor: not-allowed;
        background-color: @input-bg-disabled !important;
        border-color: @input-disabled-border-color !important;
      }

      &::after {
        //background-color: #fff;
        color: @input-disabled-color !important;
      }
    }

    &:indeterminate {
      & + label::after,
      & + span::after {
        display: block;
        content: " ";
        top: 8px;
        left: 5px;
        height: 3px;
        width: 10px;
        background-color: #555555;
        border-radius: 2px;
      }
    }
  }

  &.checkbox-circle {
    input[type="checkbox"],
    input[type="radio"] {
      & + label,
      & + span {
        &::before {
          border-radius: 50%;
        }
      }
    }
  }

  &.checkbox-inline {
    margin-top: 0;

    input[type="checkbox"],
    input[type="radio"] {
      & + label,
      & + span {
        padding-left: 0;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.checkbox-variant(checkbox-primary, @brand-primary);
.checkbox-variant(checkbox-danger, @brand-danger);
.checkbox-variant(checkbox-info, @brand-info);
.checkbox-variant(checkbox-warning, @brand-warning);
.checkbox-variant(checkbox-success, @brand-success);

.checkbox-variant-indeterminate(checkbox-primary, @brand-primary);
.checkbox-variant-indeterminate(checkbox-danger, @brand-danger);
.checkbox-variant-indeterminate(checkbox-info, @brand-info);
.checkbox-variant-indeterminate(checkbox-warning, @brand-warning);
.checkbox-variant-indeterminate(checkbox-success, @brand-success);

//
// Radios
// --------------------------------------------------

.radio-variant(@parent, @color) {
  .radio-awesome.@{parent} input[type="radio"] {
    & + label,
    & + span {
      &::after {
        background-color: @color;
      }
    }

    &:checked + label,
    &:checked + span {
      &::before {
        border-color: @color;
        background-color: @color;
      }

      &::after {
        background-color: #fff;
      }
    }
  }
}

.radio-awesome {
  padding-left: 20px;

  label {
    padding-left: 0;
    cursor: pointer;
  }

  input[type="radio"] {
    & + label,
    & + span {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding-left: 5px;
      min-height: 20px;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 1px;
        top: 2px;
        margin-left: -20px;
        border: 1px solid @input-border;
        border-radius: 50%;
        background-color: #fff;
        .transition(border 0.15s ease-in-out);
      }

      &::after {
        display: inline-block;
        position: absolute;
        content: " ";
        width: 6px;
        height: 6px;
        left: 6px;
        top: 7px;
        margin-left: -20px;
        border-radius: 50%;
        background-color: #fff;
        .scale(0, 0);

        .transition-transform(.1s cubic-bezier(.8, -0.33, .2, 1.33));
        //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
      }
    }

    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:focus {
      & + label,
      & + span {
        color: #000;

        &::before {
          border-color: @input-border-focus !important;
          outline: none;
          outline: thin dotted;
          outline: 1px auto @input-border-focus;
        }
      }
    }

    &:checked + label::before,
    &:checked + span::before {
      border-color: @input-color;
      background-color: @input-color;
    }

    &:checked + label::after,
    &:checked + span::after {
      .scale(1, 1);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled + label,
    &:disabled + span {
      color: @input-disabled-color !important;

      &::before {
        cursor: not-allowed;
        border-color: @input-disabled-border-color !important;
        background-color: @input-bg-disabled !important;
      }

      &::after {
        background-color: @input-disabled-color !important;
      }
    }
  }

  &.radio-inline {
    margin-top: 0;

    input[type="radio"] {
      & + label,
      & + span {
        padding-left: 0;
      }
    }
  }
}

input[type="checkbox"][readonly],
input[type="radio"][readonly] {
  &:disabled + label,
  &:disabled + span {
    color: @input-color !important;

    &::before {
      background-color: @input-bg !important;
      border-color: @input-border !important;
    }

    &::after {
      color: @input-color !important;
    }
  }
}

.radio-variant(radio-primary, @brand-primary);
.radio-variant(radio-danger, @brand-danger);
.radio-variant(radio-info, @brand-info);
.radio-variant(radio-warning, @brand-warning);
.radio-variant(radio-success, @brand-success);
